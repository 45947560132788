
@import url('https://fonts.googleapis.com/css?family=Fredoka+One|Rubik:300,300i,400,400i,500,500i,700,700i,900,900i|Schoolbell');

*{margin:0px; padding:0px; outline:none !important}
h1,h2,h3,h4,h5,h6{ margin:8px 0px; }
h2{ font-weight:600; font-size:35px; line-height:50px;}
h3{ font-size:24px; font-weight:600; line-height:35px;}
h4{ line-height:28px;}



.editor ol { margin:5px 0px;}
.editor ol  li{list-style: none; font-size:15px !important; padding-left:20px; list-style:none; margin-bottom:15px; position:relative; color:#000; line-height:25px;}
.editor ol  li a{color:#000 !important; font-size:15px !important; position:relative; }
.editor ol  li a:after{background:#000 !important;}

.editor ol  li:before{z-index: 1;
content:'';

    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
   background: rgba(1,64,134,0.6); position:absolute; top:11px; left:0px; width:10px; height:10px;}




.editor ul { margin:5px 0px;}
.editor ul  li{list-style: none; font-size:15px !important; padding-left:20px; list-style:none; margin-bottom:15px; position:relative; color:#000; line-height:25px;}
.editor ul  li a{color:#000 !important; font-size:15px !important; position:relative; }
.editor ul  li a:after{background:#000 !important;}

.editor ul  li:before{z-index: 1;
content:'';

    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
   background: rgba(1,64,134,0.6); position:absolute; top:11px; left:0px; width:10px; height:10px;}



.cursor{ cursor:pointer;}
.overflow{ overflow:hidden;}
.overflow-none{ overflow:inherit !important;}

a:focus{text-decoration:none !important;}

.full{width:100%;}

.static{ position:static;}

a:hover{text-decoration:none !important;}

h1,h2,h3,h4,h5,h6{ color:#1e1e1e;}
.weight-700{ font-weight:600 !important;}
.weight-800{ font-weight:700 !important; font-size:20px !important;}


body{overflow-X:hidden; font-family: 'Open Sans', sans-serif !important;}
.container{width:95%;}
.container-2{ width:80%; margin:auto;}

.no-margin{margin:0px;}

input:-moz-read-only { /* For Firefox */
pointer-events:none;
}

input:read-only {
  pointer-events:none;
}

.a-link{ color:#5c57b7 !important; font-weight:600; text-transform:uppercase; font-size:16px;}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #000;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #000;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #000;
}
:-moz-placeholder { /* Firefox 18- */
  color: #000;
}



::-webkit-scrollbar {

    width: 7px !important;

  cursor:pointer;

  transition:all 0.4s;

  -webkit-transition:all 0.4s;

  -moz-transition:all 0.4s;

  -o-transition:all 0.4s;

}


.height-box:hover::-webkit-scrollbar{

  width:14px;
}



/* Track */

::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;

    border-radius: 10px;

  cursor:pointer;


}


/* Handle */

::-webkit-scrollbar-thumb {

    -webkit-border-radius: 10px;

    border-radius: 10px;

    background:#f37022;


  cursor:pointer;

}

::-webkit-scrollbar-thumb:window-inactive {

  background:#fff;


}


/*--custom-checkbox and radio--*/
/* The container */
.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight:500;
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox  .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color:#cacaca;
}

/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox  input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox  .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox  input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox  .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}





/* The container */
.radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight:500;
}

/* Hide the browser's default radio button */
.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio .checkmark:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}



.parent{position: relative;}
.loader-bg{ width:100%; height:100%; position:fixed; top:0px; left:0px; z-index:1000000000000000 !important; background:#fff;
  display:none;}
.parent .loader-bg{position: absolute;}
.loader-bg img{ width:70px; position:absolute; top:40%; left:45%; transform:translate(-50%,-50%);
  -webkit-animation: rotation 3s infinite linear; -moz-animation: rotation 3s infinite linear;
  animation: rotation 3s infinite linear;
}
.loader-bg span{position: absolute;
  top: 52%;
  left: 31%;
  font-size: 16px;
  margin: 0 auto;}


.loader-bg.error{padding-top:40%;}
.loader-bg.error .text{font-size: 22px;}


@-webkit-keyframes rotation {
  from {
      -webkit-transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(359deg);
  }
}




@-moz-keyframes rotation {
  from {
      -webkit-transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(359deg);
  }
}


.form-msg{ position:absolute; width:100%; z-index: 10000000; top:10%; left:0%; text-align: center;}

.img-shape{ position:relative;}
.img-shape:before{ content:''; width:100%; height:100%; position:absolute; top:20px; left:-20px; background: rgba(0,0,0,0.1); z-index:-1;}
.img-shape.margin-bottom{ margin-bottom:50px;}

.img-shape img{width: 100%;}

.float-none{ float:none;}
.auto{margin:auto;}
.content-auto{ width:80%; margin:auto;}

.nav-icon1 {
  width: 31px;
  height: 30px;
  position: relative;
  display:none;

  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  float:right;
    position:relative;
  z-index:10000000;
}

.nav-icon1 span{
  display: block;
  position: absolute;
  height: 3px;
  width: 30px;
  background: #000;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;

}

.nav-icon1 span:nth-child(1) {
  top: 5px;
}

.nav-icon1 span:nth-child(2) {
  top: 15px;
}

.nav-icon1 span:nth-child(3) {
  top: 25px;
}

.nav-icon1.open span:nth-child(1) {
  top: 18px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.nav-icon1.open span:nth-child(3) {
  top: 18px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}



.gototop {
  -webkit-animation: hide 350ms ease-out forwards 1;
  animation: hide 350ms ease-out forwards 1;
  padding: 12px 12px;
  border: 0;
  text-align: center;
  background:#f37022;
  color: #fff;
  position: fixed;
  bottom: 82px;
  right: 16px;
  -webkit-transition: background-color 100ms ease-in-out;
  transition: background-color 100ms ease-in-out;
  cursor:pointer;
  z-index:100000;
  display:none;
}

.gototop > span {
  display: inline-block;
  -webkit-transition: transform 100ms 100ms ease-in-out;
  transition: transform 100ms 100ms ease-in-out;
}

.gototop:hover { background-color: #374375; }

.gototop:hover > span, .gototop:focus > span {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

.isvisible {
 -webkit-animation: show 1s ease-out forwards 1;
  animation: show 1s ease-out forwards 1;
}


 @-webkit-keyframes
hide {  0% {
 opacity: 1;
 -webkit-transform:translateY(0);
}


 25% {
 opacity: .5;
 -webkit-transform: rotate(-3deg) translateY(-40px);
}


 75% {
 -webkit-transform: rotate(3deg);
}

 100% {
 opacity: 0;
 -webkit-transform:translateY(100px);
}



}
@keyframes
hide {  0% {
 opacity: 1;
 transform:translateY(0);
}

25% {
 opacity: .5;
 transform: rotate(-3deg) translateY(-40px);
}

 75% {
 transform: rotate(3deg);
}

 100% {
 opacity: 0;
 transform:translateY(100px);
}


}

 @-webkit-keyframes
show {  0% {
 -webkit-transform: rotate(0deg);
}

 25% {
 opacity: .5;
 -webkit-transform: rotate(-3deg) translateY(-40px);
}

 75% {
 -webkit-transform: rotate(3deg);
}


 100% {
 opacity: 1;
 -webkit-transform: rotate(0deg);
}



}



 @keyframes
show {  0% {
 transform: rotate(0deg);
}



 25% {
 opacity: .5;
 transform: rotate(-3deg) translateY(-40px);
}



 75% {
 transform: rotate(3deg);
}



 100% {

 opacity: 1;

 transform: rotate(0deg);

}

}




.editor p,.editor .text{ font-size:15px; color:#1e1e1e; line-height:28px; font-weight:400;}
.editor ul,.editor ol{ margin:25px 0px;}
.editor ul li,.editor ol li{ list-style:none; font-size:15px; color:#1e1e1e; line-height:28px; font-weight:400;}
.editor ol li{ list-style:none;}



.btn-hover {
  text-transform: capitalize;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  overflow: hidden;
  position: relative;
  z-index: 0;
  font-weight:600 !important;
  border-radius:0px !important;
}


.btn{ text-transform:uppercase;}
.btn-custom{ padding:12px 30px; border-radius:40px !important; text-transform:uppercase; font-weight:500; border-radius:5px; }
.btn-custom .fa{ position:relative; left:7px; display:none;}
.btn-custom:hover .fa{ left:12px; transition:all 0.1s;}
.btn-custom.blue{ background:none; color:#1e1e1e; border:1px solid #1e1e1e !important;}
.btn-custom.blue:hover{ color:#fff; opacity:1; background:#3c3c3c; border:1px solid #3c3c3c !important;}

.btn-custom.border{ background:none; color:#000; border:1px solid #5a5a5a !important;}
.btn-custom.border:hover{ color:#fff; opacity:1; background:#f37022; border:1px solid #f37022 !important;}

.btn-custom.white{ background:none; color:#fff; border:1px solid #fff !important;}
.btn-custom.white:hover{ color:#fff; opacity:1; background:#f37022; border:1px solid #f37022 !important;}

.btn-custom.org{ background:#f37022; color:#fff; border:1px solid #f37022 !important;}
.btn-custom.org:hover{ background:#454545; border:1px solid #454545 !important;}

.btn-custom.border{ background:none; color:#000; border:1px solid #5a5a5a !important;}
.btn-custom.border:hover{ color:#fff; opacity:1; background:#3c3c3c; border:1px solid #3c3c3c !important;}

.btn2.border{ border:1px solid rgba(0,0,0,0.5); color:#000; font-weight:500; padding:10px 15px;}
.btn2.border:hover{ background:#444444; color:#fff; border:1px solid #444444;}
.btn2.dark{padding:10px 15px; background:#444444; color:#fff; border:1px solid #444444; font-weight:500;}
.btn2.dark:hover{ background:none; color:#000;}

.btn2.danger{padding:10px 15px; background:#d9534f; color:#fff; border:1px solid #d9534f; font-weight:500;}
.btn2.danger:hover{ background:#ca433f;}



.text-capitalize{ text-transform:capitalize !important;}
.text-capitalize:after{ background:#5c57b7 !important; width:100% !important;}
.text-capitalize:hover:after{ width:0px !important;}



.text{ font-size:15px; color:#1e1e1e; line-height:32px; font-weight:400;}
.text-sml{font-size:14px; color:#000; line-height:30px;}
strong{ font-weight:600;}
.text-white{color:#fff !important;}

.text-blue{ color:#2a619d;}


.custom-hd {
  font-size:40px;
  font-weight:500;
  text-transform:uppercase;
  color: #1e1e1e;
  position: relative;
  line-height:55px;

}


.custom-hd.text-white{ color:#fff !important;}
.custom-hd span{ display:block; width:80px; height:2px; margin:auto; background:#1e1e1e; margin-top:15px; position:relative;}
.custom-hd span:before{width:40px; height:2px; margin:auto; background:#1e1e1e; position:absolute; bottom:-10px; left:15%; content:'';}

.custom-hd label{ color:#f37022; font-weight:500;}


/*--loader start here--*/
.load1 {
    width: 50%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 99999999!important;
  display:none;
}


.load2 {
    width: 50%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    background: #fff;
    z-index: 99999999!important;
    display:none;

}


#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 10000000000 !important;

    display:none;
}
#spinner {
    display: block;
    position:absolute;
    width: 300px;
    height: auto;
  top:50%; left:50%;
  text-align:center;
  transform:translate(-50%, -50%);
}



 .preloader-dot-loading {
  display: block;
  margin:30px auto;
  width:97px;
}

.preloader-dot-loading .cssload-loading i{
  width: 19px;
  height: 19px;
  display: inline-block;
  border-radius: 50%;
  background: #004086;
}
.preloader-dot-loading .cssload-loading i:first-child{
  opacity: 0;
  animation:cssload-loading-ani2 0.58s linear infinite;
    -o-animation:cssload-loading-ani2 0.58s linear infinite;
    -ms-animation:cssload-loading-ani2 0.58s linear infinite;
    -webkit-animation:cssload-loading-ani2 0.58s linear infinite;
    -moz-animation:cssload-loading-ani2 0.58s linear infinite;
  transform:translate(-19px);
    -o-transform:translate(-19px);
    -ms-transform:translate(-19px);
    -webkit-transform:translate(-19px);
    -moz-transform:translate(-19px);
}


.preloader-dot-loading .cssload-loading i:first-child{ background:#017ac3 !important;}
.preloader-dot-loading .cssload-loading i:nth-child(2){ background:#61469d !important;}
.preloader-dot-loading .cssload-loading i:nth-child(3){ background:#d4298f !important;}
.preloader-dot-loading .cssload-loading i:nth-child(4){ background:#f68026 !important;}
.preloader-dot-loading .cssload-loading i:nth-child(5){ background:#fdb60a !important;}
.preloader-dot-loading .cssload-loading i:nth-child(5){ background:#02afaf !important;}


.preloader-dot-loading .cssload-loading i:nth-child(2),
.preloader-dot-loading .cssload-loading i:nth-child(3){
  animation:cssload-loading-ani3 0.58s linear infinite;
    -o-animation:cssload-loading-ani3 0.58s linear infinite;
    -ms-animation:cssload-loading-ani3 0.58s linear infinite;
    -webkit-animation:cssload-loading-ani3 0.58s linear infinite;
    -moz-animation:cssload-loading-ani3 0.58s linear infinite;
}
.preloader-dot-loading .cssload-loading i:last-child{
  animation:cssload-loading-ani1 0.58s linear infinite;
    -o-animation:cssload-loading-ani1 0.58s linear infinite;
    -ms-animation:cssload-loading-ani1 0.58s linear infinite;
    -webkit-animation:cssload-loading-ani1 0.58s linear infinite;
    -moz-animation:cssload-loading-ani1 0.58s linear infinite;
}
@keyframes cssload-loading-ani1{
  100%{
    transform:translate(39px);
    opacity: 0;
  }
}

@-o-keyframes cssload-loading-ani1{
  100%{
    -o-transform:translate(39px);
    opacity: 0;
  }
}
@-ms-keyframes cssload-loading-ani1{
  100%{
    -ms-transform:translate(39px);
    opacity: 0;
  }
}

@-webkit-keyframes cssload-loading-ani1{
  100%{
    -webkit-transform:translate(39px);
    opacity: 0;
  }
}

@-moz-keyframes cssload-loading-ani1{
  100%{
    -moz-transform:translate(39px);
    opacity: 0;
  }
}

@keyframes cssload-loading-ani2{
  100%{
    transform:translate(19px);
    opacity: 1;
  }
}

@-o-keyframes cssload-loading-ani2{
  100%{
    -o-transform:translate(19px);
    opacity: 1;
  }
}

@-ms-keyframes cssload-loading-ani2{
  100%{
    -ms-transform:translate(19px);
    opacity: 1;
  }
}

@-webkit-keyframes cssload-loading-ani2{
  100%{
    -webkit-transform:translate(19px);
    opacity: 1;
  }
}

@-moz-keyframes cssload-loading-ani2{
  100%{
    -moz-transform:translate(19px);
    opacity: 1;
  }
}

@keyframes cssload-loading-ani3{
  100%{
    transform:translate(19px);
  }
}

@-o-keyframes cssload-loading-ani3{
  100%{
    -o-transform:translate(19px);
  }
}

@-ms-keyframes cssload-loading-ani3{
  100%{
    -ms-transform:translate(19px);
  }
}

@-webkit-keyframes cssload-loading-ani3{
  100%{
    -webkit-transform:translate(19px);
  }
}

@-moz-keyframes cssload-loading-ani3{
  100%{
    -moz-transform:translate(19px);
  }
}



/*--loader ends here--*/



section{ padding:55px 0px;}

.bg-blue{ background:#0089d1;}

.transition{transition:all 0.4s; -webkit-transition:all 0.4s; -moz-transition:all 0.4s; -o-transition:all 0.4s;}

.padding0{padding:0px !important;}
.padding-bottom0{ padding-bottom:0px;}
.bold{font-weight:bold !important;}


.border-effect {
  position: relative;
  color: #ff3296;
  text-decoration:none;
}
.border-effect:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 1px;
  background-color: #94c93d;
  content: "";
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.border-effect:hover {
  text-decoration:none;
  color:#94c93d;
}
.border-effect:hover:after {
  width: 100%;
}





/*----top strip,start here--*/
.top-strip{ width:100%; height:40px; padding:0px 0px; overflow:hidden; border-bottom:1px solid rgba(255,255,255,0.1);}
.top-strip .col-md-11{ width:85%;}
.top-strip .col-md-11 ul li{ margin:0px 5px;}
.top-strip .col-md-1{ width:15%;}
.top-strip .col-md-1 .footer-socials i{ top: 6px !important;}
.top-strip ul{float:right; position:relative; top:10px;}
.top-strip ul li a{ color:#fff; display:block; font-size:13px; opacity:0.9; transition:all 0.3s;}
.top-strip ul li a:hover{opacity:1;}
.top-strip .footer-socials{ margin-top:-6px;}
/*----top strip,ends here--*/






/*--header start here--*/
.header-fixed{ position:fixed; top:0px; left:0px; z-index:1000000; height:120px; width:100%; background:linear-gradient(rgb(0, 0, 0) 0%, transparent 100%);}
.header-fixed .container-fluid{ position:relative;}
.header-fixed.darkHeader {
    top: -40px;
    transition: .2s ease-in-out all;
    -webkit-transition: .2s ease-in-out all;
    -ms-transition: .2s ease-in-out all;
    -moz-transition: .2s ease-in-out all;
    -o-transition: .2s ease-in-out all;
  height:100px;
  background:#fff;
  box-shadow:-10px 0px 15px rgba(0,0,0,0.2);
}

.header-fixed.darkHeader header{ height:66px;}

header.has-login .col-md-7{width:59.333333%;}
header.has-login .col-md-2{width:15.666667%;}


.header-fixed.darkHeader nav ul { margin-top:3px;}
.header-fixed.darkHeader nav ul li a{ padding:18px 0px; position:relative; color:#000 !important; opacity:1 !important; font-weight:600;}
.header-fixed.darkHeader nav ul li a .fa{color: #626262 !important; }
.header-fixed.darkHeader nav ul li a:hover .fa{ color:#626262 !important;}

.header-fixed.darkHeader nav .submenu a{ padding:10px 15px;}
.header-fixed.darkHeader .logo{ transform:scale(0.6);  -webkit-transform:scale(0.6); -moz-transform:scale(0.6); -o-transform:scale(0.6); top:5px;}
.header-fixed.darkHeader nav ul{ top:0px;}
.header-fixed.darkHeader nav ul.submenu{ top:100%;}
.header-fixed.darkHeader .submenu a{ font-weight:400;}

.header-fixed.darkHeader header .col-md-2 ul{ top:15px;}
.header-fixed.darkHeader header .col-md-2 ul a{ font-weight:600 !important;}
.header-fixed.darkHeader header .btn-custom.white{ border:1px solid rgba(0,0,0,0.8) !important ; color:#000 !important;}
.header-fixed.darkHeader header .btn-custom.white:hover{ border:1px solid #f37022 !important ; color:#fff !important; background:#f37022 !important;}

.header-fixed.darkHeader .submenu.mega-menu li a{ background:none; color:#000 !important; padding:3px 0px !important; font-weight:400 }
.header-fixed.darkHeader .submenu.mega-menu li a.a-link{color:#5c57b7 !important;}
.header-fixed.darkHeader .submenu.mega-menu .btn{ padding:12px 30px !important;}
.header-fixed.darkHeader .submenu.mega-menu .btn.org{ background:#f37022; color:#fff !important;}

.header-fixed.darkHeader .my-acc-drop{ top:-8px !important;}
.header-fixed.darkHeader .my-acc-drop .dropdown-menu { top:100% !important;}
.header-fixed.darkHeader .my-acc-drop  .dropdown-toggle *{ color:#626262 !important;}

header{ width:100%; height:auto; margin:auto; padding:0px 0px; }
header .col-md-10{ padding-right:0px;}
header .logo{ position:relative; top:13px;}

header .col-md-2 ul{ float:right; position:relative; top:22px;}
header .col-md-2 ul li a{ display:block; padding:7px 20px; font-weight:500 !important; font-size:12px; }
header .col-md-2 ul li a.org:hover{ background:#f37022 !important; border:1px solid #f37022 !important;}




nav{ position:relative; }
nav .login-section{ display:none;}

nav ul{ float:right; margin-top:13px;}
nav ul li{ float:left; list-style:none; margin:0px 13px; }
nav ul li a{ color:#fff; font-weight:400 ; font-size:15px; display:block; padding:15px 0px; position:relative; z-index:1; position:relative; top:0px; text-transform:uppercase;
  opacity:1;}
nav ul li a .fa{ font-size:16px;}
nav ul li a label{ overflow:hidden; cursor:pointer; font-weight:400;}

nav ul li a:hover{ color:#fff; opacity:1;}
nav ul li a:focus{ color:#fff;}
nav ul li a:hover .fa{ color:#fff !important;}
nav ul li a:hover:before{ width:100%; left:0px; opacity: 1;}


nav .submenu{ background:none ; transform:rotateX(0deg) !important;  -webkit-transform:rotateX(0deg) !important; top:120%; padding:0px !important;
margin:0px !important; visibility:hidden !important}

nav .submenu:before{ content:''; width: 0;  height: 0;   border-left: 10px solid transparent; border-right: 10px solid transparent; border-bottom: 15px solid #f6f6f6;
  bottom:0px; position:relative;}

nav .submenu li{ margin:0px;}
nav .submenu li a{ padding:10px 15px; line-height:25px; color:#000; font-weight:500; font-size:15px; border-bottom:1px solid rgba(0,0,0,0.1); position:relative;
  text-transform:capitalize; background:#f6f6f6; opacity:1 !important; }
  nav .submenu li a:focus{color:black !important;}
nav .submenu li a .fa{ float:right; color:#000; font-size:16px; position:relative; top:5px; position:relative; left:-10px;}
nav .submenu li.act a{color:#000; background:#f4f6f7;}
nav .submenu li.act a .fa{color:#000 !important;}
nav .submenu li:last-child a{ border-bottom:0px;}
nav .submenu li a:hover{ color:#000; background:#f8f8f8;}

nav .submenu li>.submenu{ background:#fff; left:120%; top:0px !important;}
nav .submenu li>.submenu li a{ color:#000; border-bottom:1px solid rgba(255,255,255,0.1);}

nav ul li:hover .submenu{ top:100%; visibility:inherit !important;}

.submenu li:hover .submenu{ background:#017ac3; left:100%;  }


.mega-menu-wrap{ position:static;}
.mega-menu{ width:96% !important; left:2.5% !important; overflow:hidden; }
.mega-menu.remove{opacity:0 !important; visibility:hidden !important; height:0px; overflow:hidden;}
.submenu.remove{opacity:0 !important; visibility:hidden !important;height:0px; overflow:hidden;}
.mega-menu:before{ left:69%; bottom:14% !important;}
.mega-menu .col{ width:18.75%; height:400px; background:#f6f6f6; float:left; padding:30px; }
.mega-menu .col1{ width:25%;}
.mega-menu .col .text-sml{ line-height:24px; font-size:14px;}
.mega-menu .col h3{ margin-bottom:20px; color:#000; font-size:24px;}
.mega-menu .col a.a-link{ background:none; padding:0px; color:#5c57b7 !important; font-weight:600 !important; border-bottom:0px !important;}

.mega-menu .col li a{ background:none; padding:3px 0px; border-bottom:0px; color:#1e1e1e; font-size:14px;}
.mega-menu .col li a:hover{ background:none;}
.mega-menu .col li a .fa{ float:left; left:0px; margin-right:5px; opacity:0.7;}
.mega-menu .col li a:hover .fa{ color:#000 !important;}
.mega-menu .col .btn{ font-size:13px; font-weight:600 !important; width:230px; margin-left:-40px;}
.mega-menu .col .btn img{ width:25px; float:left; position:static !important; opacity:0.8;}
.teligram-icon{ position:relative;}


.mega-menu img{ position:absolute; bottom:-20px; right:0px;}
.mega-menu .col .btn.border:hover{ color:#000 !important; background:none !important;}
.mega-menu .col .btn.teligram-icon img{ width:30px !important; position:absolute !important; top:7px; left:10px;}

nav ul li:hover .mega-menu{ top:65% !important;}

.my-acc-drop{ float:right; top:0px !important; position:relative;}
.my-acc-drop .img{ width:30px; height:30px;  border-radius:100%; display:block; float:left;  margin-top:12px; margin-right:5px;}
.my-acc-drop .img .fa{ font-size:22px; position:relative; top:8px;}
.my-acc-drop .dropdown-toggle{ color:#fff; font-size:16px; }
.my-acc-drop .dropdown-toggle label{ position:relative; margin-top:18px; font-weight:500;}
.my-acc-drop .dropdown-menu{ padding:0px; margin:0px; border-radius:0px !important; top:110%; position:absolute;}
.my-acc-drop .dropdown-menu a{ color:#000 !important; font-weight:500 !important; font-size:14px !important;}
.my-acc-drop li{ list-style:none; cursor:pointer;}
.my-acc-drop li *{cursor:pointer;}
.my-acc-drop ul{ border:0px !important;}
.my-acc-drop li{ margin:0px; padding:0px;}
.my-acc-drop li a{ padding:10px 15px !important; color:#000; font-size:14px; font-weight:500;}



/*---header ends here---*/



/*--home slider start here--*/
.home-slider-wrap{ position:relative;}
.home-slider{ overflow:hidden; height: 80vh;}
.home-slider .slide{ position:relative; overflow:hidden; background-size:cover !important;
  background-repeat: no-repeat !important; background-position: top center !important;  }
.home-slider .slide img{width:100%; -webkit-animation: zooming 14s ease-in-out infinite alternate; animation: zooming 14s ease-in-out infinite alternate;}

.home-slider .slide:before{ content:''; width:100%; height:100%;  top:0px; left:0px; position:absolute; z-index:1;
    background: #6460aa !important;
    background: -webkit-linear-gradient(left,#6460aa 0,#000000 100%) !important;
    background: linear-gradient(to right,#6460aa 0,#000000 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6460aa', endColorstr='#000', GradientType=1 );  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=46)";
    -webkit-opacity: .60;
    -khtml-opacity: .60;
    -moz-opacity: .60;
    -ms-opacity: .60;
    -o-opacity: .60;
    opacity: .60;}


.home-slider1 .slide:before{ content:''; width:100%; height:100%;  top:0px; left:0px; position:absolute; z-index:1;
    background: #6460aa !important;
    background: -webkit-linear-gradient(left,#a73661 0,#1375b5 100%) !important;
    background: linear-gradient(to right,#a73661 0,#1375b5 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6460aa', endColorstr='#000', GradientType=1 );  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=46)";
    -webkit-opacity: .70;
    -khtml-opacity: .70;
    -moz-opacity: .70;
    -ms-opacity: .70;
    -o-opacity: .70;
    opacity: .70;}



.home-slider4 .slide:before{ content:''; width:100%; height:100%;  top:0px; left:0px; position:absolute; z-index:1;
    background: #6460aa !important;
    background: -webkit-linear-gradient(left,#000 0,#464646 100%) !important;
    background: linear-gradient(to right,#000 0,#464646 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6460aa', endColorstr='#000', GradientType=1 );  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=46)";
    -webkit-opacity: .70;
    -khtml-opacity: .70;
    -moz-opacity: .70;
    -ms-opacity: .70;
    -o-opacity: .70;
    opacity: .70;}

.home-slider2 .slide:before{ background:rgba(0,0,0,0.6) !important;}
.home-slider3 .slide:before{ display:none !important;}

.home-slider3 .slide .overlay{  padding:35px;}



.home-slider .slide .overlay{ position:absolute; top:50%; left:50%; width:95%; z-index:1; text-align:left; transform:translate(-50%, -50%);}
.home-slider .slide .overlay h2{ color:#fff; font-size:85px; font-weight:700; margin-bottom:30px;}
.home-slider .slide .overlay h3{ color:#fff; font-size:30px; font-weight:400;}

.home-slider img{ width:100% !important;}


.home-slider  .owl-nav{display:none !important;}
.home-slider .owl-carousel{height:100vh;}
.home-slider .owl-dots { position:absolute; width: 100%; bottom:20px; right:3%; text-align: right; }
.home-slider .owl-dots button span{ width:8px !important; height:8px !important; margin:5px !important; background:#fff !important;}
.home-slider .owl-dots button.active span{ background:#11b4be !important;}
.home-slider .owl-nav .owl-prev{ position:relative; top:-27px; left:-25px;}
.home-slider .owl-nav.owl-next{ position:relative; top:-27px; right:-25px;}


.arrow-down{ position:absolute; bottom:20px; left:45%; z-index:100000; width:80px;}



@-webkit-keyframes zooming {

    0% {

        -webkit-transform: scale(1.0);

    }



    100% {



      -webkit-transform: scale(1.2);

    }

}





@keyframes zooming {

    0% {

        transform: scale(1.0);

    }



    100% {

        transform: scale(1.2);

    }

}

/*--home slider ends here--*/



/*about start here--*/
section.about{ padding-top:0px;}
section.about .container{ width:90%;}
section.about h4{ color:#1e1e1e;}
section.about .col-md-7{ padding-right:100px;}
.about-img{ position:relative; width:380px; height:350px; float:right;}
.about-img:before{ content:''; width:100%; height:100%; position:absolute; top:10px; left:-90px; border:8px solid #eaeaea; opacity:0.7;}
.about-img:after{ content:''; width:90%; height:100%; position:absolute; top:70px; left:20px; background:url(../images/bg-repeater.png);}
.about-img .img{ position:relative; z-index:100; position:relative; left:-50px; top:40px; overflow:hidden; }
.about-img .img img{transition:all 0.3s; height:350px;}


/*about ends here--*/




/*upcoming-event start here--*/
.owl-nav{display:block !important; position:absolute; top:-40px; right:10px;}
 .owl-nav *{opacity:1 !important;}
 .owl-nav button{background:#eaeaea !important; margin:0px 2px;}
.owl-nav span{ font-size: 31px;

  display: block;
  width: 30px;
  height: 38px;
  line-height: 30px;
  color: #000 !important; }
.owl-nav *:hover{ background:#dedede !important; opacity:1 !important; color:#000 !important;}



.upcoming-events{ margin:25px 0px;}
.upcoming-events .col-md-4 { padding:0px 5px; width:inherit;}
.upcoming-events .col-md-4 .img{ margin-bottom:25px; height:261px; overflow:hidden; position:relative;}
.upcoming-events .col-md-4 .img .overlay{ position:absolute; top:0px; left:0px; width:100%; height:100%; background:rgba(0,0,0,0.6); z-index:100; opacity:0;}
.upcoming-events .col-md-4 .img .overlay .fa{ font-size:20px; position:absolute; bottom:30px; right:30px; color:#fff;}
.upcoming-events .col-md-4:hover img{transform:scale(1.050);}
.upcoming-events .col-md-4 h2{ font-weight:600; font-size:25px; margin-bottom:10px;}
.upcoming-events .col-md-4 h2 a{ color:#1e1e1e; transition:all 0.4s; -webkit-transition:all 0.4s; -moz-transition:all 0.4s; -o-transition:all 0.4s;}
.upcoming-events .col-md-4 h4{ font-size:16px; margin-bottom:15px; font-weight:600; color:#000; line-height:20px;}
.upcoming-events .col-md-4 h4 img{ width:inherit !important; float:left; margin-right:10px; opacity:0.6;}
.upcoming-events .col-md-4 span.date{ font-weight:600; color:#000; font-size:16px;}
.upcoming-events .col-md-4 span.date img{width:inherit !important; float:left; margin-right:10px; opacity:0.6;}
.upcoming-events .col-md-4 .multiple-location{ font-weight:600; color:#000; font-size:16px; margin-top:15px; display:none;}
.upcoming-events .col-md-4 .multiple-location img{ width:inherit !important; float:left; margin-right:5px; opacity:0.8;}
.upcoming-events .col-md-4 .multiple-location a.border-effect{ color:#4a4a4a !important; font-weight:400 !important; margin-left:10px;}
.upcoming-events .col-md-4 .multiple-location a.border-effect:hover{ color:#f37022 !important;}

.upcoming-events .col-md-4:hover span.date img{transform:scale(1) !important;}
.upcoming-events .col-md-4:hover h4 img{transform:scale(1) !important;}
.upcoming-events .col-md-4:hover .multiple-location img{transform:scale(1) !important;}
.upcoming-events .col-md-4:hover .overlay{ opacity:1;}
.upcoming-events .col-md-4:hover h2 a{ color:#f37022;}
.upcoming-events .col-md-4 .img .overlay .fa:hover{ color:#f37022;}

.upcoming-events .owl-nav{ margin-top:-25px !important; right:0px !important;}


.upcoming-events-page .col-md-4{ width:33.3333333333%; margin-bottom:50px;}
.upcoming-events-page-full{ padding-bottom:0px;}

/*upcoming-event ends here--*/





/*--features start here--*/
.features{ padding:60px 0px; background:url(../images/features.jpg);  background-repeat: no-repeat; background-attachment: fixed; background-position: center;
 background-size: cover; height:auto;}
.features *{color:#fff;}

.features .col-md-8{ margin:auto; float:none;}

.features-section{ margin-top:25PX; text-align:center;}
.features-section .col-md-4{ text-align:center; background:rgba(0,0,0,0.3); height:280px; padding:50px 30px; width:30%; margin:10px; float:none; display:inline-block;
  vertical-align:top; border:1px solid rgba(255,255,255,0.1);}

.features-section .category-link{ text-align:center; background:rgba(0,0,0,0.3); height:50px; padding:50px 30px; width:30%; margin:10px; float:none; display:inline-block;
  vertical-align:top; border:1px solid rgba(255,255,255,0.1);}

.features-section .col-md-4 *{opacity:0.9;}
.features-section .col-md-4  h3{ margin-top:30px; font-weight:400;}



/*--footer start here--*/
footer{ width:100%; height:auto; margin:auto; background:url(../images/footer-bg.jpg);  background-attachment: fixed;   background-position: center; background-repeat: no-repeat; background-size: cover; padding:45px 0px; padding-bottom:25px;}
footer .col-md-4 .text-sml{ width:95%;}

footer .txt{opacity:1;}
footer h4{font-size:17px;}
footer h3{font-size:22px; font-weight:500; position:relative;}
footer h3:before{ content:''; width:15%; height:1px; position:absolute; bottom:-15px; left:0px; background:#fff;}



footer .footer-socials { position:relative; top:3px;}
.footer-socials li{ padding:2px;}
.footer-socials li a{ display:block; width:30px; height:30px; border-radius:0px; background:rgba(255,255,255,0.1); color:#575555 !important;  text-align:center;
border-radius:100px !important; opacity:1 !important;}
.footer-socials li a .fa{color:#fff !important; position:relative; top:11px; font-size:11px;}

.footer-socials li a:hover{  color:#fff !important;  background:rgba(255,255,255,0.2); color:#575555 !important;}
.footer-socials li a:hover .fa{ color:#fff !important;}

.copyright .footer-socials li a{ width:40px; height:40px; }
.copyright .footer-socials li a .fa{ font-size:20px !important;}

.copyright .text-sml{ position:relative; top:5px;}

.footer-links li{ margin-bottom:15px; width:100%; float:left;}
.footer-links li a{ color:#fff; font-size:14px; font-weight:500; opacity:1; transition:all 0.3s;}
.footer-links li a .fa{ margin-right:5px;}
.footer-links li a:hover{opacity:1;}

.footer-contact li{ width:100%; clear:both; margin-bottom:25px;}
.footer-contact li .fa{ margin-right:10px; font-size:20px; float:left;}
.footer-contact li span{ position:relative; top:3px;}
.footer-contact li *{color:#fff; opacity:0.6;}

.copyright{ width:100%; height:45px;  margin:auto; padding:10px 0px; border-top:1px solid rgba(255,255,255,0.1);}
.copyright *{ color:#fff; }
.copyright .text-sml{opacity:1;}
.copyright ul{ float:right;}
.copyright li a{ color:#fff; font-size:12px; opacity:0.6; transition:all 0.3s;}
.copyright li a:hover{ opacity:1;}

/*--footer ends here--*/






.sidebar{ width:300px; height:auto; background:none; background:#f3f4f5; }
.sidebar li{ margin-bottom:3px;}
.sidebar li a{ display:block; padding:15px 15px; color:#000; transition:all 0.3s; border-left:2px solid transparent; font-weight:600; font-size:15px;}
.sidebar li .fa{ display:block; padding:15px 15px; color:#000; transition:all 0.3s; border-left:2px solid transparent; font-weight:500; font-size:15px;
  border-bottom:1px solid rgba(0,0,0,0.1);}
.sidebar li a:hover{ border-left:2px solid rgba(42,97,157,0.3); background:#fafafa}
.sidebar li a.act{ border-left:2px solid rgba(42,97,157,0.3); background:#fafafa}
.sidebar li:last-child a{ border-bottom:0px !important;}
.sidebar li a:focus{color: red !important;}


.inner-banner{ width:100%; height:330px; margin:auto; position:relative; padding-top:13%; background-attachment:fixed !important;}
.inner-banner:before{ content:''; width:100%; height:100%; position:absolute; top:0px; left:0px; background:rgba(0,0,0,0.5); z-index:1;}
.inner-banner:after{ content:''; width:100%; height:5px; position:absolute; bottom:0px; left:0px; z-index:2;}
.inner-banner h1{color:#fff !important; position:relative; z-index:2; font-size:50px; font-weight:500; text-shadow:0 0 30px rgba(0,0,0,0.1), 0 0 30px rgba(0,0,0,0.1), 0 0 11px rgba(0,0,0,0.1); text-transform:uppercase;}

.breadcrumb{ position:relative; z-index:2; background:none; float:right; position:absolute; bottom:-10px; right:50px; display:none;}
.breadcrumb *{ color:#fff !important; font-size:16px; font-weight:500;}


.inner-banner h1{ text-transform: capitalize !important;}
.inner-banner h1:before{display:none;}
.inner-banner h1:after{display:none;}

.inner-banner h1 span:before{display:none;}
.inner-banner h1 span:after{display:none;}

.inner-banner2{ height:538px;}
.inner-banner2 .text{ text-align:center; position:relative; z-index:2; width:80%; margin:auto;}
.inner-banner2 .col-md-8{ position:absolute; bottom:30px; width:80%; left:10%; z-index:2; float:none; margin:auto; margin-top:60px;}
.inner-banner2 .col-md-8 *{ color:#fff; font-weight:600;}
.inner-banner2 .col-md-8 h2{font-size:50px; font-weight:500;}
.inner-banner2 .col-md-8 h3{font-size:20px;}



/*---team start here--*/
.team-wrapper .col-md-8{ margin:auto; float:none;}

.team2 .col-md-3:nth-child(2){ padding-top:100px;}
.team-box{ margin-bottom:45px; width:100%; display: inline-block; width:25%; vertical-align: top;}
.team-box .img{ width:220px; height:220px; border-radius:100%; overflow:hidden; box-shadow:2px 2px 5px 0px rgba(0, 0, 0, 0.2); transition:all 0.3s;}
.team-box .data{ width:85%; margin:auto;}
.team-box h3{ color:#1e1e1e; font-weight:700; font-size:22px;}
.team-box h4{ font-size:14px; color:#f26c4f; opacity:0.8; margin-bottom:15px; font-weight:600;}
.team-box .text{ text-align:justify;}
.team-box:hover .img{ box-shadow:2px 2px 5px 0px rgba(0, 0, 0, 0.4); transform:translateY(-10px);}

/*---team ends here--*/


/*---photo gallery start here--*/
.gallery{ padding-top:100px;}


.gallery h2{ font-weight:300; font-size:50px; text-align:right; line-height:85px;}
.photo-gallery li{ width:30%; height:auto; cursor:pointer; margin:10px; padding:0px !important;}
.photo-gallery li .img{ widows:100%; height:200px; overflow:hidden; position:relative;}
.photo-gallery li .img .overlay{ position:absolute; top:0px; left:0px; width:100%; height:100%; background:rgba(0,0,0,0.7); opacity:0; transform-origin:50% 100%; transform:rotateX(-90deg);}
.photo-gallery li .img .overlay .fa{ position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); color:#fff; font-size:25px;}
.photo-gallery li:hover .overlay{opacity:1; transform:rotateX(0deg);}

.photo-gallery li .img .tags{ position:absolute; bottom:-200px; left:10px; z-index:100;}
.photo-gallery li .img .tags li{ width:inherit !important; float:left; margin:4px 4px !important; transition:all 0.3s; transition-delay: 0.3s; position:relative; bottom:-200px;}
.photo-gallery li .img .tags li a{ color:#fff; display:block; padding:5px 10px; border-radius:15px; border:1px solid #fff; font-size:11px; transition:all 0.1s; font-weight:500;}
.photo-gallery li .img .tags li a:hover{ background:#fff; color:#000;}
.photo-gallery li:hover .img .tags{ bottom:20px;}
.photo-gallery li:hover .img .tags li{ bottom:0px;}



.gallery-filter { margin-bottom:25px; position:relative; display:none;}
.gallery-filter .dropdown-toggle{ color:#fff; padding:15px 35px; text-transform:uppercase; display:block; text-align:center; font-weight:500; background:#2a619d;}
.gallery-filter .dropdown-menu{ width:100%;}
.gallery-filter .dropdown-menu li a{ color:#000; padding:10px 15px; border-bottom:1px solid rgba(0,0,0,0.1);}

/*---photo gallery ends here--*/


/*---video gallery start here--*/
.team-overlay{ position: fixed; top:0px; left:0px; width: 100%; height: 100vh; z-index: 10000000; background: rgba(0,0,0,0.5); display: none;}
.video-gallery{ text-align:center;}
.video-gallery li{ width:30%; display:inline-block; margin:10px; overflow:hidden;}
.video-gallery li .embed-responsive{ position:relative;}
.video-gallery li .overlay{ position:absolute; top:0px; left:0px; background:rgba(0,0,0,0.5); z-index:1000; width:100%; height:100%; cursor:pointer; opacity:0; }
.video-gallery li:hover .overlay{opacity:1;}
.video-gallery li:hover .embed-responsive{transform:scale(1.050);}

.video-gallery li .overlay img{ position:absolute; top:38%; left:41%; color:#000; font-size:22px; }

.video-popup{ position:fixed; top:50%; left:50%; width:70%; height:auto; transform:translate(-50%,-50%); -webkit-transform:translate(-50%,-50%); -moz-transform:translate(-50%,-50%); -o-transform:translate(-50%,-50%); background:#000; z-index:100000000; box-shadow:0 2px 10px rgba(0, 0, 0, 0.5) !important; display:none;}

.video-popup .close-video { position:absolute; top:-20px; right:-20px; color:#000; font-size:25px; background:#fff; display:block; width:35px; height:35px; border-radius:100px;
  text-align:center; cursor:pointer;}
.video-popup .close-video i{ position:relative; font-size:17px;}

/*---video gallery ends here--*/




.vision-mission{ width:90%; margin:auto; text-align:center;}
.vision-mission .col-md-6{ padding:45px 80px; }
.vision-mission h2{ margin-top:40px; font-size:32px; color:#1e1e1e; padding-bottom:35px; position:relative;}
.vision-mission h2 span{ display:block; width:70px; margin:auto; background:#1e1e1e; height:2px; position:relative; top:15px;}




.inception{ width:90%; margin:auto;  overflow:hidden;}

.inception:nth-child(even) .col-md-6:last-child{ position:relative; left:50px;}





/*--contact start here--*/
.contact-section .col-md-4{ padding:25px 30px; border-right:1px solid rgba(0,0,0,0.060);}
.contact-section .col-md-4 img{opacity:0.6;}
.contact-section .col-md-4 h4{ margin-top:15px; font-size:16px; line-height:25px;}

.contact-form{ background:#f9f9f9; padding-bottom:0px;}
.contact-form iframe{ width:100%; height:400px; border:0px; margin-top:-50px;}
.contact-form-box{ width:80%; height:auto; margin:auto; background:#fff; box-shadow:0 0 10px rgba(0, 0, 0, 0.050); padding:45px 35px; position:relative; z-index:1;}
.contact-form h4{ margin-top:20px;}

.form{ width:100%; height:auto;}
.form.box2 li{ width:50%;}
.form.box3 li{ width:33.3333333333%;}
.form li.full{ width:100%;}
.form li.full textarea{ height:100px; padding-top:10px;}
.form li{height:auto; float:left; list-style:none;     margin: 0px 0px 25px;    padding: 0px 6px 0px 6px;}
.form li .form-control{ border-radius:0px; height:48px; border:1px solid #f1f1f1 !important;  transition:all 0.4s; -webkit-transition:all 0.4s; -moz-transition:all 0.4s;
-o-transition:all 0.4s; font-size:16px; color:rgba(0,0,0,0.6);}
.form li .form-control:hover{ border:1px solid rgba(0,0,0,0.2) !important;}
/*--contact ends here--*/




.support-form{ background:#f9f9f9;}


/*--faq start here--*/

.faq .col-md-9{ padding:0px 50px;}
.faq h2{ padding-left:15px; }
.faq-section{ padding:0px 0px; margin-bottom: 20px;}

.accordion-container{
  position: relative;
  margin:25px auto;

  height: auto;
  margin: 10px auto;
}
.accordion-container > h2{
  text-align: center;
  color: #fff;
  padding-bottom: 5px;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
}
.accordion-container .set{
  position: relative;
  width: 100%;
  height: auto;
  background-color:none;
}
.accordion-container .set > a{
  display: block;
  padding: 15px 15px;
  text-decoration: none;
  color: #1e1e1e;
  font-weight: 600;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  -webkit-transition:all 0.1s;
  -moz-transition:all 0.1s ;
  transition:all 0.1s ;
  font-size:18px;
}
.accordion-container .set > a i{
  float: right;
  margin-top: 2px;
  opacity:0.6;
}

.accordion-container .set > a.active i{opacity:1 !important;}

.accordion-container .set .content{
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  display:none;
  padding:5px 15px;
}
.accordion-container .set .content p{
font-size:15px; color:#1e1e1e; line-height:32px; font-weight:400;
padding:0px 0px;
}
.accordion-container .set .content.act{ display:block;}



.accordion-container .faq-acc{
  position: relative;
  width: 100%;
  height: auto;
  background-color:none;
}
.accordion-container .faq-acc > a{
  display: block;
  padding: 15px 15px;
  text-decoration: none;
  color: #1e1e1e;
  font-weight: 600;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  -webkit-transition:all 0.1s;
  -moz-transition:all 0.1s ;
  transition:all 0.1s ;
  font-size:18px;
}
.accordion-container .faq-acc > a i{
  float: right;
  margin-top: 2px;
  opacity:0.6;
}

.accordion-container .faq-acc > a.active i{opacity:1 !important;}

.accordion-container .set .content{
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  display:none;
  padding:5px 15px;
}
.accordion-container .faq-acc .content p{
font-size:15px; color:#1e1e1e; line-height:32px; font-weight:400;
padding:0px 15px;
}
.accordion-container .faq-acc .content.act{ display:block;}








.faq-banner{ width:300px; height:400px; background:url(../images/email-bg.png) no-repeat bottom 50px right -100px #2a619d; padding:20px; text-align:center;}
.faq-banner h3{ border-bottom:1px solid rgba(255,255,255,0.5); padding-bottom:15px; font-size:26px;}
.faq-banner h4{ font-size:20px;}
.faq-banner .btn{ background:#fff; color:#000;}
/*--faq ends here--*/






/*login start here--*/

.login-page{  background:#2a619d; background-size:cover; padding-top:40px; color:#000; height:106vh;}
.register{ padding:30px 0px; height:724px;}
.login-page .btn{ border-radius:0px;}
.login-page .card{ background:#fff; width:32%; height:auto; margin:auto; border-radius:5px; box-shadow:0 2px 10px rgba(0, 0, 0, 0.5) !important; padding:25px 15px; position:relative; margin-top:10px;}
.login-page .card h2{font-weight:400; font-size:28px; }
.login-page .card h2 strong{font-weight:400;}
.login-page .card form{ padding:15px 20px;}

.login-page .card form .input-group{ margin-bottom:20px;}

.login-page .card form label{font-weight:400;}
.login-page .card form a.border-effect{ color:#000;}
.login-page .card form a.border-effect:after{background:#000;}

.login-page .card form .form-line{ border-bottom:1px solid rgba(0,0,0,0.2); position:relative;}
.login-page .card form .form-line .line{ width:0px; height:1px; background:#4266b2; position:absolute; bottom:-1px; left:0px; z-index:100; transition:all 0.3; -webkit-transition:all 0.3s; -moz-transition:all 0.3s; -o-transition:all 0.3s;}

.login-page .card form .form-line input:focus + .line{width:100%;}

.login-page .card form .input-group-addon{ font-size:16px;}
.login-page .card form .btn{ background:#2a619d; color:#fff; font-size:17px; text-align:center; padding:15px 10px;}
.login-page .card form .btn:hover{ background:#21548c;}

.login-page .card .btn.create-account{ border:1px solid rgba(0,0,0,0.2) !important; margin-top:15px; background:none; color:rgba(0,0,0,0.8); font-size:13px;}
.login-page .card .btn.create-account:hover{background:none;}



.input-group {
    width: 100%;
    margin-bottom: 20px;
}

.input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group {
    margin-right: -2px;
    z-index: 3;
}
.input-group.addon-line .input-group-addon {
    border: none;
    background-color: transparent;
    padding-left: 0;
    font-weight: bold;
}
.input-group .input-group-addon {
    text-align: center;
    color: #777;
    background-color: #eee;
}

.input-group-addon .material-icons {
    font-size: 16px;
    vertical-align: middle;
}

.input-group.input-group-sm .input-group-addon i {
    font-size: 14px;
}

.input-group.input-group-lg .input-group-addon i {
    font-size: 26px;
}

.input-group .input-group-btn .btn {
    padding: 6px 12px;
    font-size: 14px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.input-group .input-group-addon.btn {
    padding: 7px 12px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.input-group .form-line {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.input-group .form-line:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    bottom: -2px;
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -moz-transition: 0.25s ease-in;
    -o-transition: 0.25s ease-in;
    -webkit-transition: 0.25s ease-in;
    transition: 0.25s ease-in;
    border-bottom: 2px solid #1f91f3;
}

.input-group .form-line + .input-group-addon-line {
    padding-right: 0;
    padding-left: 10px;
}

.input-group .help-info {
    float: right;
    font-size: 12px;
    margin-top: 5px;
    color: #999;
}

.input-group label.error {
    font-size: 12px;
    display: block;
    margin-top: 5px;
    font-weight: normal;
    color: #F44336;
}

.input-group .form-line.error:after {
    border-bottom: 2px solid #F44336;
}

.input-group .form-line.success:after {
    border-bottom: 2px solid #4CAF50;
}

.input-group .form-line.warning:after {
    border-bottom: 2px solid #FF9800;
}

.input-group .form-line.focused:after {
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.input-group .form-line.focused .form-label {
    bottom: 25px;
    left: 0;
    font-size: 12px;
}

.input-group .input-group-addon-line {
    border: none;
    background-color: transparent;
    padding-left: 0;
    font-weight: bold;
}

.input-group .input-group-addon-line {
    border: none;
    background-color: transparent;
    padding-left: 0;
    font-weight: bold;
}

.input-group .input-group-addon-line .material-icons {
    font-size: 18px;
    color: #555;
}

.input-group .form-line input[type="text"],
.input-group .form-line .form-control {
    border: none;
    box-shadow: none;
    padding-left: 0;
}

.input-group .form-control:focus {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    box-shadow: none !important;
}

.input-group.input-group-sm .input-group-addon-line i {
    font-size: 14px;
}

.input-group.input-group-sm .form-control {
    font-size: 12px;
}

.input-group.input-group-lg .input-group-addon-line i {
    font-size: 26px;
}

.input-group.input-group-lg .form-control {
    font-size: 18px;
}

.login-wrapper{ width:100%; height:100vh; background:#2a619d;}
.login-wrapper .card{ width:40%; height:300px; background:#fff; margin:auto; border-radius:5px; box-shadow:0 2px 10px rgba(0, 0, 0, 0.5) !important; padding:25px;}

.register .card{ width:50%;}
 .register .card .input-group{ width:50%; float:left;  margin: 0px 0px 35px;
    padding: 0px 10px 0px 10px;}
/*login ends here--*/



.get-in-touch{ position:fixed; top:45%; right:-75px; width:auto; height:auto; padding:7px 15px; text-transform:uppercase; background:#253349; cursor:pointer; color:#fff;
  z-index:100000; transform:rotate(90deg); font-size:17px; font-weight:600; letter-spacing:3px;}





/*--get in touch popup start here--*/
.modal{z-index:100000000;}
.modal-backdrop{ z-index:10000000;}
.modal .close{ background:#f37022; opacity:1; padding:5px; color:#fff; position:relative; top:0px; right:50px; width:30px; height:30px; font-size:14px;}

.autoload .col-md-7{ padding:25px;}
.autoload .modal-dialog{width:70%;}
.autoload .bg{height:561px; background:url(../images/email-bg.png) no-repeat bottom 50px right -70px #253349; width:100%; padding:35px 35px;}
.autoload .bg *{color:#fff !important;}
.autoload .bg  .rows{ margin-bottom:30px; overflow:hidden;}
.autoload .bg  .rows .text{opacity:0.6; font-weight:400;}
.autoload .bg .col-md-1{ width:10%; float:left; padding:0px;}
.autoload .bg .col-md-1 .fa{ position:relative; top:10px; font-size:20px;}
.autoload .bg .col-md-11{ width:90%; float:left; padding:0px;}
.autoload .bg h2{ margin:0px;}
.autoload .row .col-md-7{padding-top:20px;}
.autoload .close{ position:absolute; top:-15px; right:-10px; font-weight:500; }

.autoload .form-control{ border:0px !important; border-bottom:1px solid rgba(0,0,0,0.1) !important; padding-left:0px; outline:none !important; box-shadow: none !important;
  color:#000;}


.form2  .form-control{ border:0px !important; border-bottom:1px solid rgba(0,0,0,0.1) !important; padding-left:0px; outline:none !important; box-shadow: none !important;
  color:#000;}

.autoload ul li{ width:100%; margin-bottom:20px;}
.autoload .footer-socials li{ width:inherit;}

/*--get in touch popup ends here--*/



/*--cookies-bar start here--*/
.cookies-bar{ width:100%; height:70px; margin:auto; position:fixed; bottom:-400px; left:0px; z-index:100000; padding:15px 0px; background: -webkit-linear-gradient(left,#6460aa 0,#000000 100%) !important;
    background: linear-gradient(to right,#6460aa 0,#000000 100%) !important;}
.cookies-bar.open{ bottom:0px;}
.cookies-bar h3{ font-size:17px; position:relative; top:-2px; font-weight:300;}
.cookies-bar h3 a{ color:#fff; text-decoration:underline; font-weight:600;}
.cookies-bar .col-md-3 a{ float:right;}
/*--cookies-bar ends here--*/




.test-count{ padding:25px 0px; margin-bottom:35px; overflow:hidden;}
.test-count h2{ font-size:35px; font-weight:500; color:#444444; display:block; width:80px; height:80px; margin:auto; border-radius:100px; background:#ececec; text-align:center;
  line-height:80px; box-shadow:2px 2px 5px 0px rgba(0, 0, 0, 0.2); margin-bottom:20px;}



/*--testimonials start here--*/
.testimonial-wrapper .col-md-3{ padding:0px 7px;}
.testimonials{ width:100%; height:auto;}
.testimonials li{ width:100%; height:auto; margin:auto; background:#f7f7f7; padding:20px; padding-top:60px; position:relative; margin-bottom:20px;
transition:all 0.4s; -webkit-transition:all 0.4s; -moz-transition:all 0.4s; -o-transition:all 0.4s;}
.testimonial-wrapper .col-md-3:first-child .testimonials li:nth-child(2n+0){ background:#f3f3f3;}
.testimonial-wrapper .col-md-3:first-child .testimonials li:nth-child(2n+0){ background:#0a9eec;}
.testimonial-wrapper .col-md-3:first-child .testimonials li:nth-child(2n+0) *{color:#fff;}

.testimonial-wrapper .col-md-3:first-child .testimonials li:nth-child(3n+2){ background:#f37022;}
.testimonial-wrapper .col-md-3:first-child .testimonials li:nth-child(3n+2){ background:#f37022;}
.testimonial-wrapper .col-md-3:first-child .testimonials li:nth-child(3n+2) *{color:#fff;}


.testimonial-wrapper .col-md-3:nth-child(2n+0) .testimonials li:nth-child(2n+0){ background:#f3f3f3;}
.testimonial-wrapper .col-md-3:nth-child(2n+0) .testimonials li:nth-child(2n+0){ background:#0a9eec;}
.testimonial-wrapper .col-md-3:nth-child(2n+0) .testimonials li:nth-child(2n+0) *{color:#fff;}


.testimonial-wrapper .col-md-3:nth-child(2n+0) .testimonials li:nth-child(3n+2){ background:#6460aa;}
.testimonial-wrapper .col-md-3:nth-child(2n+0) .testimonials li:nth-child(3n+2){ background:#6460aa;}
.testimonial-wrapper .col-md-3:nth-child(2n+0) .testimonials li:nth-child(3n+2) *{color:#fff;}


.testimonial-wrapper .col-md-3:nth-child(3n+0) .testimonials li:nth-child(3n+3){ background:#0a9eec;}
.testimonial-wrapper .col-md-3:nth-child(3n+0) .testimonials li:nth-child(3n+3){ background:#0a9eec;}
.testimonial-wrapper .col-md-3:nth-child(3n+0) .testimonials li:nth-child(3n+3) *{ color:#fff;}

.testimonial-wrapper .col-md-3:last-child .testimonials li:nth-child(4n+1){ background:#f37022;}
.testimonial-wrapper .col-md-3:last-child .testimonials li:nth-child(4n+1){ background:#f37022;}
.testimonial-wrapper .col-md-3:last-child .testimonials li:nth-child(4n+1) *{color:#fff;}


.testimonials li .text{ line-height:25px; text-align:justify;}
.testimonials li i{ font-size:30px; position:absolute; top:20px; left:20px; color:#069cea;  opacity:0.5;}
.testimonials li:hover{transform:translateY(-10px); box-shadow:0 0 10px rgba(0, 0, 0, 0.1);}

/*--testimonials ends here--*/





.cat-details .col-md-8{ padding-right:75px;}
.cat-details .info h2{ margin-bottom:15px; }
.cat-details .info h3{ margin-bottom:15px; }
.cat-details .info h4{ font-size:15px; margin:0px;}
.cat-details .info2 h4{ font-size:20px; margin-bottom:0px; margin-top:0px; font-weight:500;}
.cat-details .info h4 span.left{width:35%; float: left;}
.cat-details .info h4 span.label{margin-top:3px;}
.cat-details .info h4 span.right{width:65%; float: left;}
.cat-details .info h4 span{ display:block; width:58%; float:right; color:#000;}
.cat-details .info h4 span .fa{opacity:0.7;}
.cat-details .info3 h4{ font-size:15px !important; margin-bottom:0px; margin-top:0px; font-weight:500;}
.cat-details .info3 h3{ font-size:20px;}

.cat-details .col-md-4 .img{ position:relative;}
.cat-details .col-md-4 .img img{width: 100%;}
.cat-details .col-md-4 .img:before{ content:''; width:100%; height:100%; position:absolute; top:20px; left:-20px; background: rgba(0,0,0,0.1); z-index:-1;}


.multiple-location-box{ width:100%; height:auto; margin:auto; position:relative; box-shadow:inset -1px 0 0 #e1e1e1, inset 1px 0 0 #e1e1e1; padding:20px; margin-bottom:70px;}
.multiple-location-box.block{ display:block; margin-bottom:40px;}
.multiple-location-box.block .col-md-8{ padding-right:25px;}
.multiple-location-box.block .col-md-8 .box{ padding-right:25px;}
.multiple-location-box.block .col-md-4 .box{ border-right:0px;}
.multiple-location-box:last-child{margin-bottom:0px !important;}
.multiple-location-box2{ padding:30px;}
.multiple-location-box2 h2{ line-height:40px;}
.multiple-location-box:before{ content:''; width:100%; height:25px;  background:url(../images/top-border.png) repeat-x top center;
  position:absolute; top:-25px; left:0px;}
.multiple-location-box:after{ content:''; width:100%; height:25px;  background:url(../images/bottom-border.png) repeat-x top center;
  position:absolute; bottom:-25px; left:0px;}

.multiple-location-box{ display:table;}
.multiple-location-box .col{ float:none; display:table-cell; vertical-align:top;  position:relative;}
.multiple-location-box .col .box{border-right:1px solid #e1e1e1;}
.multiple-location-box .col:last-child{ border-right:0px;}
.multiple-location-box .col:last-child{ padding-top:30px;}
.multiple-location-box .col .btn{ width:100% !important;}
.multiple-location-box .col h4{ margin-bottom:0px; margin-top:0px;}
.multiple-location-box .col-md-4{ padding-top:43px;}








.success-story h2{ line-height:40px;}
.success-story .img{ margin-top:25px; position:relative;}
.success-story .img:before{ content:''; width:90%; height:100%; position:absolute; top:20px; left:-7px; background: rgba(0,0,0,0.1); z-index:-1;}

.story-wrap .contact-form-box{ margin-bottom:25px;}
.story-wrap .contact-form-box h3{ line-height:35px; font-weight:700;}
.story-wrap .contact-form-box:last-child{ margin-bottom:0px;}



.dull{opacity:1; pointer-events:none;}
.dull .checkbox .checkmark{background: #f1f1f1 !important;}

table .checkbox{ font-size:14px; margin-top:0px; margin-bottom:0px;}


#book-confirm .close{right: 6px !important; top: 6px !important;}



.custom-overlay{ position:fixed; top:0px; left:0px; width:100%; height:100%; z-index:100000; background:rgba(0,0,0,0.7); z-index:1000000;
cursor:url(../images/close-panel.png), auto; display:none;}

.sidebar-popup{ position:fixed; top:50%; left:50%; transform:translate(500%,-50%); -webkit-transform:translate(500%,-50%); -moz-transform:translate(500%,-50%); -o-transform:translate(500%,-50%); background:#f37022; width:30%; height:auto; z-index:10000000; padding:25px;  border-radius:5px; box-shadow:0 2px 10px rgba(0, 0, 0, 0.5) !important;}

.sidebar-popup.open{ transform:translate(60%,-50%); -webkit-transform:translate(60%,-50%); -moz-transform:translate(60%,-50%); -o-transform:translate(60%,-50%); }

.sidebar-popup select{ height:50px; padding-left:15px !important;}

.sidebar-popup .btn:hover{ background:#fff; color:#000;}









.how-does-it-work{ position:relative; height:auto; margin-top:-100px;}
.how-does-it-work .shape1{ position:absolute; top:0px; left:0px; width:100%;}
.how-does-it-work .col-md-4{ position:relative; margin:45px 0px;}
.how-does-it-work .col-md-4 h3{ font-weight:600; margin-bottom:20px;}
.how-does-it-work .col-md-4 .counting{ font-size:100px; font-weight:bold; position:absolute; top:-130px; left:0px; width:100%; opacity:0.050;}




.book-slot .top-wrap{background:#f7f7f7; padding:25px; box-shadow:0 0 10px rgba(0, 0, 0, 0.1);}
.book-slot .top-wrap .col-md-6 h3.hd{ height:40px;}
.book-a-slot-location{ width:100%; height:350px; margin:auto;}

.volunteer-img{ margin-top:-50px;}


.pdf-wrapper{ margin-top:0px;}
.pdf-view{ width:60%; height:400px; border:0px;}





.request-for-volunteer-img{ margin-top:0px;}






/*admin css start here--*/

.admin{ background:#eef2f5; height:100vh;}


.form{ width:100%; height:auto;}
.form.box2 li{ width:50%;}
.form.box3 li{ width:33.3333333333%;}
.form li.full{ width:100%;}
.form li.full textarea{ height:100px; padding-top:10px;}
.form li{height:auto; float:left; list-style:none;     margin: 0px 0px 25px;    padding: 0px 6px 0px 6px;}
.form li .form-control{ border-radius:0px; height:48px;  transition:all 0.4s; -webkit-transition:all 0.4s; -moz-transition:all 0.4s;
-o-transition:all 0.4s; font-size:16px; color:rgba(0,0,0,0.6);}
.form li .form-control:hover{ border:1px solid rgba(0,0,0,0.2) !important;}



.wrapper h3{font-weight:500;}
.wrapper .left-part{ width:21%;}
.wrapper .right-part{ width:78%; padding:0px; padding-right:30px; float:right;}

.wrapper .sidebar-nav{background-image: linear-gradient(#10163a, #1d2b7e); box-shadow:0 0 15px 0 rgba(0,0,0,.05); position:fixed; top:0px; left:0px; height:100%; width:282px; z-index:100000;}
.wrapper .sidebar-nav nav{max-height:100%; overflow:scroll; overflow-x:hidden;}
.wrapper .sidebar-nav nav::-webkit-scrollbar{ width:4px !important;}
.wrapper .sidebar-nav nav:hover::-webkit-scrollbar{ width:4px !important;}

.wrapper .sidebar-nav nav::-webkit-scrollbar-thumb{ background:#ccc !important;}
.wrapper .sidebar-nav .logo-wrap{padding:25px 15px;}
.wrapper .sidebar-nav .logo{ width:150px;}




.wrapper .nav{display: block; max-height: 500px; overflow-y: scroll;}
.wrapper .nav::-webkit-scrollbar{width:5px !important; background: none;}
.wrapper .nav::-webkit-scrollbar-track{background: none !important;}
.wrapper .nav::-webkit-scrollbar-thumb{ background: rgba(255,255,255,0.5);}
.wrapper .nav ul li a{ font-size:14px; display:block; padding:15px 9px; color:rgba(255,255,255,0.7) !important; font-weight:400;}
.wrapper .nav ul li a .icon{ width:40px; float:left; text-align:center;}
.wrapper .nav ul li:last-child a{ border-bottom:0px;}
.wrapper .nav ul li a:hover{color:rgba(255,255,255,0.9) !important; background:rgba(255,255,255,0.1);}
.wrapper .nav ul li a.active{background:rgba(255,255,255,0.1);}

.wrapper .nav ul li  ul{ padding:0px !important; }
.wrapper .nav ul li  ul li{ list-style:none;}
.wrapper .nav ul li  ul li a{ font-size:13px; padding:15px 50px; }



.set{
  position: relative;
  width: 100%;
  height: auto;
}
.set > a{
  display: block;
  text-decoration: none;

  -webkit-transition:all 0.1s;
  -moz-transition:all 0.1s ;
  transition:all 0.1s ;
}
.set > a i{
  float: right;
  margin-top: 2px;
  opacity:0.6;
}

.set > a.active i{opacity:1 !important; }

.set .content{

  display:none;
  padding:5px 15px;
}
.set .content p{
font-size:15px; color:#1e1e1e; line-height:32px; font-weight:400;
padding:0px 0px;
}
.set .content.act{ display:block;}



.wrapper header{ width:75.4%; height:60px; background:#fff !important; position:fixed; top:15px; right:2.2%; z-index:100000; border-radius:5px; box-shadow:0 4px 20px 0 rgba(0,0,0,.05);
}

.wrapper header:before{content: ''; width: 100%; height: 100%;
  background: linear-gradient(180deg,hsla(0,0%,97.3%,.95) 44%,hsla(0,0%,97.3%,.46) 73%,hsla(0,0%,100%,0)); position: absolute; top:-30px; left:0px;
  z-index: -1; display: none;}

  .wrapper header.scroll:before{ display:block;}

.wrapper header + div{ margin-top:75px;}

.wrapper header .logo{display:none;}

.wrapper header h3{ font-weight:500; position:relative; top:3px; font-size:22px;}

.wrapper header .col-md-5{ float:right;}
.wrapper header .my-acc-drop{ float:right;}
.wrapper header .my-acc-drop .img{ width:35px; height:35px; overflow:hidden; border-radius:100%; display:block; float:left;  margin-top:12px;}
.wrapper header .my-acc-drop .img img{width:100%;}
.wrapper header .my-acc-drop .dropdown-toggle{ color:#000; font-size:16px;}
.wrapper header .my-acc-drop .dropdown-toggle label{ position:relative; margin-top:18px; font-weight:500;}
.wrapper header .my-acc-drop .dropdown-menu{ top:128%; padding:0px; margin:0px; left:inherit;}
.wrapper header .my-acc-drop li{ list-style:none; cursor:pointer;}
.wrapper header .my-acc-drop li *{cursor:pointer;}
.wrapper header .my-acc-drop ul{ border:0px !important;}
.wrapper header .my-acc-drop li{ margin:0px; padding:0px;}
.wrapper header .my-acc-drop li a{ padding:15px 15px; color:#000; font-size:14px; font-weight:500;}



.search{ width:220px; height:auto;  float:left; position:relative; top:9px;}
.search .field{ width:100%; height:40px; border-radius:25px; background:rgba(255,255,255,0.2); padding-left:20px; border:0px; color:#fff;}
.search .field::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff;
}
.search .field::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}
.search .field:-ms-input-placeholder { /* IE 10+ */
  color: #fff;
}
.search .field:-moz-placeholder { /* Firefox 18- */
  color: #fff;
}

.search .field:focus{ background:#fff;}
.search .field:focus::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #000;
}
.search .field:focus::-moz-placeholder { /* Firefox 19+ */
  color: #000;
}
.search .field:focus:-ms-input-placeholder { /* IE 10+ */
  color: #000;
}
.search .field:focus:-moz-placeholder { /* Firefox 18- */
  color: #000;
}

.search button{ background:none; border:0px; position:absolute; top:15px; right:15px; color:#fff;}
.search .field:focus ~ button{ color:#000;}



.dashboard-box{width:100%; margin:20px auto; margin-top:0px; padding:0px 10px; margin-bottom:20px; display:flex;  justify-content: space-between; }
.dashboard-box li{ background:url(../images/bg-1.png) no-repeat top center #7a6fbe; background-size:cover; flex:1; margin:5px; list-style:none; height:130px;
  padding:20px; position:relative; transition:all 0.3s; cursor:pointer; border-radius:5px;}
.dashboard-box li a{display:block;}
.dashboard-box li *{color:#fff;}
.dashboard-box li h3{ font-size:16px; margin-top:0px;}
.dashboard-box li .count{ font-size:25px; font-weight:500; margin:20px 0px;}
.dashboard-box li .fa{ float:right; margin-top:-40px; font-size:22px;}


.wrapper .right-part .wrap{ padding:0px 15px;}
.card{ width:100%; height:auto; margin:auto; padding:25px; border-radius:5px; background:#fff; margin:0px; box-shadow:0 0 15px 0 rgba(0,0,0,.05);}
.card h2,.card h3,.card h4,.card h5,.card h6{ margin-top:0px;}

.card h3{ margin-bottom:15px; font-size:20px;}

.table-responsive::-webkit-scrollbar{ height:3px !important;}
.table-responsive::-webkit-scrollbar-thumb{ background:#ccc !important;}
table th{ font-weight:600 !important; color:#000; font-size:15px; white-space:nowrap;}
table td{color:#000; font-size:13px;}


.pagination-wrap{ padding:0px 0px; border-top:1px solid rgba(0,0,0,0.1); position:relative; }
.product-count{ float:left; position:absolute; top:38px; left:-40px; z-index:10000; width:200px;}
.pagination{width:100%; text-align:center; padding-top:20px; position:relative; top:-10px;}
.pagination li{display:inline-block; margin:0px 1px; }
.pagination li img{width:100% !important;}
.pagination a{ transition:all 0.3s; color:#000 !important; font-size:15px; border:0px !important; font-weight:500;}
.pagination a.act{ background:#ff3e85 !important; color:#fff !important;}
.pagination a:hover{ background:#6460aa !important; color:#fff !important;}
.pagination li.active a{background:#6460aa !important; color:#fff !important; border:0px !important;}

.pagination li.prev-next a{ padding:6px 15px; font-size:16px; position:relative; top:0px; }
.pagination li.prev-next.next a{margin-left:20px; border:1px solid rgba(0,0,0,0.5) !important; border-radius:4px !important;}
.pagination li.prev-next.next a:hover{ background:none !important; color:#000 !important;}

.paginate_button{font-size: 10px !important; font-weight: bold !important;}

.valunteer-details  .row{ padding:8px 0px;}
.valunteer-details  .row *{color:#000;}
.valunteer-details  .row .col-xs-4 strong{ white-space:nowrap;}



.custom-overlay{ position:fixed; top:0px; left:0px; width:100%; height:100%; z-index:100000; background:rgba(0,0,0,0.7); z-index:1000000;
cursor:url(../images/close-panel.png), auto; display:none;}

.sidebar-popup{ position:fixed; top:50%; left:50%; transform:translate(500%,-50%); -webkit-transform:translate(500%,-50%); -moz-transform:translate(500%,-50%); -o-transform:translate(500%,-50%); background:#f37022; width:30%; height:auto; z-index:10000000; padding:25px;  border-radius:5px; box-shadow:0 2px 10px rgba(0, 0, 0, 0.5) !important;}

.sidebar-popup.open{ transform:translate(60%,-50%); -webkit-transform:translate(60%,-50%); -moz-transform:translate(60%,-50%); -o-transform:translate(60%,-50%); }

.sidebar-popup select,.sidebar-popup .form-control{ height:50px; padding-left:15px !important;}

.sidebar-popup .btn:hover{ background:#fff; color:#000;}

/*admin css ends here--*/



.form-overlay{position:relative;}
.form-overlay .overlay{position:absolute; top:0px; left:0px; width:100%; height:100%; z-index: 10000000;
  background:#fff;  display: none;}
  .form-overlay .overlay.open{display:flex !important; align-items: center;}
  .form-overlay .overlay .text{width:80%; margin:auto; font-size: 18px; position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);}

  .mydp, .mydp .headertodaybtn{border:0px !important;}

  .modal .footer-socials li a .fa{top:5px !important}

  .error-msg{ width:100%; height:auto; margin:auto; background:#EDE04D;
  text-align: center; display:none; padding:1px 0px;}
  .error-msg h3{ color:#000; font-size:18px; font-weight:500; text-align:center; flex:1;}



  table .btn{ padding: 4px 8px !important;}
  table th{white-space: nowrap !important; vertical-align: middle !important;}
  table td{white-space: nowrap !important; vertical-align: middle !important;}

  .elegible-students td{vertical-align: top !important;}


  .lightbox{z-index: 100000000 !important;}
  .lightboxOverlay{z-index: 1000000 !important;}




@media not all and (min-resolution:.001dpcm) {

  select{  appearance: none;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */}

}



.prev-test{position: absolute !important;top: 83%;}




/*----------08-06-20-------------*/




         .tag-ul {margin-left: 0px; overflow: hidden;}
         .tag-ul li {float: left; padding: 0px;}
         .tag-ul li:first-child span{margin-left: 0px;}
         .tag-ul li span{padding: 4px 7px; background: #e8e8e8; color: #333; display: inline-block; float: left; margin-left: 6px; display: inline-block; border-radius: 3px;}
          .tag-ul li img{width: 8px; margin-right: 6px; display: inline-block;}
         .float-r{float: right;}



.product-filter h3{ padding-bottom: 5px; margin-bottom: 0px; margin-top: 0px;}

.product-filter .filter-box:last-child{border-bottom: 0px;}



.filter-box{padding: 5% 0px 5% 0%;}
.filter-box ul li label .color {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 100px;
    position: relative;
    top: 5px;
    margin-right: 8px;
}


.filter-box ul{padding-left: 0px;}
.filter-box ul li {list-style: none;}

.filter-box ul li label{font-weight: 600; margin-bottom: 0px; font-size: 15px; color: #363636c9;}
.filter-box h4{font-weight: 700;}
.product-listing-box{width: 23.43%; margin: 0px 15px 0px 0px; position: relative; padding: 0px;}
.product-listing-box{position: relative; overflow: hidden; transition: 0.2s; margin-bottom: 25px;}
.product-listing-box h4{z-index: 11; position: relative; padding-top: 5px; transition: 0.3s;}
.product-listing-box .overlay{position: absolute; width: 100%; height: 30%; bottom: 0; background: #333; opacity: 0; transition: 0.3s;}
.product-listing-box .overlay ul{position: relative; top: -16px; transition: 0.3s;}
.product-listing-box .overlay ul li{color:#fff; font-size: 18px;     padding: 5px 17px; cursor: pointer;}

.product-listing-box:hover .overlay{opacity: 1;}
.product-listing-box:hover h4{color:#fff;}
.product-listing-box:hover .overlay ul{top: 16px;}
.product-listing-box:hover{ box-shadow: 0px 2px 16px 4px rgba(40, 44, 63, 0.09);}
.filter-box.top h3 a{    float: right;
    font-size: 15px;
    text-decoration: none;
    color: #333;
    font-weight: 400;}
.action-btn-mobile{ display:none;}

#filter-fixed{padding-right: 0px; }
#filter-fixed-02{display: none;}

.test-card-listing-patent{padding-left: 45px;}


#filter-fixed{padding-right: 0px; }
#filter-fixed-02{display: none;}



/*---check-box------------*/

/* .checkbox,
.radio {
    display: inline-block;
    margin-bottom: 15px
}

.checkbox:hover,
.radio:hover {
    cursor: pointer
}

.indent {
    padding-left: 30px
}

.indent .icon-checkbox,
.indent .icon-radio {
    margin-left: -30px
}

.checkbox input[type=checkbox],
.radio input[type=radio] {
    display: none
}

.icon-checkbox,
.icon-radio {
    height: 20px;
    width: 20px;
    margin-right: 5px;
    display: inline-block;
    border: 1px solid #e4e4e4;
    position: relative;
    bottom: -2px;
    border-radius: 3px;
    background:#fff;
}

.checkbox input[type=checkbox]:disabled+i,
.checkbox:hover .icon-checkbox,
.radio input[type=radio]:disabled+i,
.radio:hover .icon-radio {
    border-color: #f37022
}

.checkbox input[type=checkbox]:checked+i:after,
.radio input[type=radio]:checked+i:after {
    content: "";
    position: absolute;
    top: 6px;
    left: 6px;
    height: 8px;
    width: 8px;
    background: #f37022
}

.checkbox input[type=checkbox]:disabled:checked+i:after,
.radio input[type=radio]:disabled:checked+i:after {
    background-color: #f37022
}

.radio input[type=radio]+i,
.radio input[type=radio]:checked+.icon-radio:after {
    border-radius: 50%
}

.disabled:hover {
    cursor: not-allowed
}

.checkbox-xs .icon-checkbox,
.checkbox-xs .icon-radio,
.radio-xs .icon-checkbox,
.radio-xs .icon-radio {
    height: 15px;
    width: 15px
}

.checkbox-xs.indent,
.radio-xs.indent {
    padding-left: 25px
}

.checkbox-xs.indent .icon-checkbox,
.checkbox-xs.indent .icon-radio,
.radio-xs.indent .icon-checkbox,
.radio-xs.indent .icon-radio {
    margin-left: -25px
}

.checkbox-xs input[type=checkbox]:checked+i:after,
.checkbox-xs input[type=radio]:checked+i:after,
.radio-xs input[type=checkbox]:checked+i:after,
.radio-xs input[type=radio]:checked+i:after {
    top: 4px;
    left: 4px;
    height: 5px;
    width: 5px
}
.product-full{background: url(../images/body-pattern.png); width: 100%; background-repeat: repeat; padding: 100px 0px 40px 0px; }
.product-full h4{font-size: 21px;} */





.filter-box .tag-ul{padding-top: 25px; padding-bottom: 30px;}
.filter-box.top .tag-ul{border-bottom: 1px solid #eaeaea;}




.test-filter-full{background-color: #f6f6f6;}



.test-card-listing-head {padding-bottom: 20px; border-bottom: 1px solid #eaeaea;}
.test-card-listing-head ul{list-style: none; padding-left: 0px; overflow: hidden; display: table; width: 100%; margin-bottom: 0px; padding-top: 12px;}
.test-card-listing-head ul li{display: inline-block; font-size: 18px; line-height: 28px; font-weight: 700; width: 15%; float: left; padding-left: 10px;}
.test-card-listing-head ul li:first-child{width: 31%; padding-left: 0px;}
.test-card-listing-head ul li:nth-child(5){width: 12%;}
.test-card-listing-head ul li:nth-child(2){width: 12%;}

.test-card-listing-body{padding-top: 20px;}
.test-card {background: #fff; padding: 12px; border-radius: 8px; margin-bottom: 15px;}
.test-card .btn-custom{padding: 9px 20px; z-index: 1;}
.test-card ul{ margin-bottom: 0px; display: table; width: 100%;}
.test-card ul li{display: inline-block; font-size: 15px; line-height: 24px; font-weight: 600; width: 15%;  margin-bottom: 0px; position: relative; padding-left: 10px; display: table-cell; vertical-align: middle; float: none;}
.test-card ul li:first-child{width: 31%; padding-left: 0px;}
.test-card ul li:nth-child(5){width: 12%;}
.test-card ul li:nth-child(2){width: 12%;}
.test-card-listing-body ul li .test-location-img{width:20px;}
.test-card ul li .img-sec{ display: inline-block; float: left; padding-right: 10px; margin-top: 9px;}
.test-card ul li .img-sec img{width: 30px; height: 30px; border-radius: 50%;}

.badge{font-weight: 600;}

.test-card .card-title{font-weight: 700; color: #f37022;}



.sort-panel{position: fixed; bottom: 0px; z-index: 10000; width: 100%; height: 100%; left: 0px; opacity: 0; visibility: hidden; }
.sort-panel .overlay{background:rgba(0,0,0,0.3); opacity: 0; visibility: hidden; transition: 0.3s; position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; z-index: 100;}
.sort-panel .content-box{width: 100%; height: auto; padding: 0px 15px 15px 15px; background-color: #fff; position: absolute; bottom: -100%; z-index: 1000; transition: 0.3s;}
.sort-panel .content-box h4{border-bottom: 1px solid rgba(0,0,0,0.1); padding-bottom: 15px; padding-top: 15px; font-weight: 700;}
.sort-panel .content-box ul{list-style: none;}


.sort-panel.open{visibility: visible; opacity: 1;}
.sort-panel.open .overlay{visibility: visible; opacity: 1;}
.sort-panel.open .content-box{bottom: 0px;}


.address-type li{width: 100%;}


.tooltip-parent{position: relative;}

.tooltip-parent .tooltip {
background: #333;
bottom: 100%;
color: #fff;
display: block;
/*left: -12%;*/
margin-bottom: 15px;
opacity: 0;
padding: 11px;
font-size: 14px;
pointer-events: none;
position: absolute;
width: max-content;
-webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
   -o-transform: translateY(10px);
    transform: translateY(10px);
-webkit-transition: all .25s ease-out;
  -moz-transition: all .25s ease-out;
  -ms-transition: all .25s ease-out;
   -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
-webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
   -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.tooltip-parent .tooltip:before {
bottom: -20px;
content: " ";
display: block;
height: 20px;
left: 0;
position: absolute;
width: 100%;
}

/* CSS Triangles - see Trevor's post */
.tooltip-parent .tooltip:after {
border-left: solid transparent 10px;
border-right: solid transparent 10px;
border-top: solid #333 10px;
bottom: -10px;
content: " ";
height: 0;
left: 50%;
margin-left: -13px;
position: absolute;
width: 0;
}

.tooltip-parent:hover .tooltip {
opacity: 1;
pointer-events: auto;
-webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
   -o-transform: translateY(0px);
    transform: translateY(0px);
}

/* IE can just show/hide with no transition */
.lte8 .tooltip-parent .tooltip {
display: none;
}

.lte8 .tooltip-parent:hover .tooltip {
display: block;
}




/*--------09-6-2020---------------*/


.cart-box{    overflow: hidden; padding:10px 5px; background: #fff; border-radius: 8px; margin-bottom: 15px;}
.cart-box-heading {    overflow: hidden; padding:10px 15px; background: #fff; border-radius: 8px; margin-bottom: 20px;}
.cart-box-heading h3{margin: 0px; }
.cart-box-body-left ul li{list-style: none; font-size: 15px; padding-bottom: 2px; font-weight: 600;}
.cart-box-body-left ul li:first-child{padding-bottom: 0px;}
.cart-box-body-left ul li img{width: 20px;}
.cart-box-body-left ul li h4{font-weight: 700;}
.cart-box-body-left ul li h4 span{color: #868686; font-weight: 600;}
.cart-box-body-left ul li.location-li{padding-top: 4px;}

.cart-box-body-right{padding-left: 0pc;}
.cart-box-body-right .trash-btn{padding-left: 20px;}
.cart-box-body-right .trash-btn img{width: 20px;}
.cart-box-body-right .trash-btn span{padding: 3px; border-radius: 50%; background: #f5f5f5; width: 42px; height: 42px; vertical-align: middle; display: block; text-align: center;}
.cart-box-body-right ul{list-style: none; padding-left: 0px; margin-bottom: 0px; float: right; }
.cart-box-body-right ul li{font-size: 21px;  display: inline-block;  font-weight: 500;}
.cart-box-body-right ul li:first-child{}


.cart-box-footer{border-top: 1px dashed rgba(0,0,0,0.1); padding-top: 10px;}
.cart-box-footer-left ul {list-style: none; padding-left: 0px; margin-bottom: 0px;}
.cart-box-footer-left ul li{display: inline-block; font-size: 15px;  font-weight: 600; width: 20%; float: left; padding: 0px 10px; border-right: 1px solid rgba(0,0,0,0.1); text-align: center;}
.cart-box-footer-left ul li:first-child{padding-left: 0px; text-align: left; width: 15%;}
.cart-box-footer-left ul li:nth-child(3){width:15%; }
.cart-box-footer-left ul li:nth-child(2){width:22%; }
.cart-box-footer-left ul li:nth-child(4){width:23%; }
.cart-box-footer-left ul li:last-child{width: 25%;}
.cart-box-footer-left ul li span{display: block; text-transform: uppercase; color: #868686;}

.cart-box-footer-right {text-align: center;}
.cart-box-footer-right h3{font-size: 23px; color: #007119; font-weight: 700;}


.cart-payment-box{overflow: hidden; padding:10px 15px; background: #fff; border-radius: 8px;}

.cart-payment-box-body-ul{list-style: none; padding-left: 0px; padding-top: 30px; }
.cart-payment-box-body-ul li{font-size: 15px; font-weight: 600; padding-bottom: 11px; display: block; overflow: hidden;}
.cart-payment-box-body-ul li span{width: 50%; float: left;}
.cart-payment-box-body-ul li:last-child {margin-top: 20px; padding-top: 15px; padding-bottom: 15px; border-top: 1px dashed rgba(0,0,0,0.1); border-bottom: 1px dashed rgba(0,0,0,0.1); font-weight: 700;}
.cart-payment-box-body-ul li span:last-child{float: right; text-align: right;}


.cart-payment-box-footer-ul{list-style: none; padding-left: 0px; padding-top: 20px; }
.cart-payment-box-footer-ul li{font-size: 15px; font-weight: 600; padding-bottom: 11px; display: block; overflow: hidden;}
.cart-payment-box-footer-ul li span{width: 50%; float: left;}
.cart-payment-box-footer-ul li span:last-child{float: right; text-align: right;}


.cart-payment-box-heading{border-bottom: 1px solid rgba(0,0,0,0.1); margin-bottom: 0px; padding-bottom:10px;}
.text-grn{color: #007119;}

.text-red{color: #ff0314;}

.mobile-checkout{display: none;}
.mt-t-20{margin-top: 20px;}

.cart-review-btn-grp ul li:first-child{float: left;}
.cart-review-btn-grp ul li:last-child{float: right;}


/*update*/
/* .checkbox, .radio {
    margin-top: 4px !important;
} */

/*Check Box*/
[class*=icheck-] {
    min-height: 22px;
    margin-top: 0px!important;
    margin-bottom: 4px!important;
    padding-left: 0;
}
.icheck-warning>input:first-child:not(:checked):not(:disabled):hover+input[type=hidden]+label::before,
.icheck-warning>input:first-child:not(:checked):not(:disabled):hover+label::before {
    border-color: #f37022
}

.icheck-warning>input:first-child:not(:checked):not(:disabled):focus+input[type=hidden]+label::before,
.icheck-warning>input:first-child:not(:checked):not(:disabled):focus+label::before {
    border-color: #f37022
}

.icheck-warning>input:first-child:checked+input[type=hidden]+label::before,
.icheck-warning>input:first-child:checked+label::before {
    background-color: #f37022;
    border-color: #f37022
}
[class*=icheck-]>input:first-child+input[type=hidden]+label::before, [class*=icheck-]>input:first-child+label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 15px;
    height: 15px;
    border: 1px solid #D3CFC8;
    border-radius: 0;
    margin-left: -29px;
    top: 3px;
}
[class*=icheck-]>input:first-child:checked+input[type=hidden]+label::after, [class*=icheck-]>input:first-child:checked+label::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0px;
    left: -3px;
    width: 6px;
    height: 9px;;
    border: 2px solid #fff;
    border-left: none;
    border-top: none;
    transform: translate(7.75px,4.5px) rotate(45deg);
    -ms-transform: translate(7.75px,4.5px) rotate(45deg);
}
.filter-box .tag-ul{ padding-top: 6px; padding-bottom: 5px;}
.tag-ul li{ margin-bottom: 5px; }
.filter-box .tag-ul li{ cursor: pointer; }
.filter-box .tag-ul li :hover{ text-decoration: line-through;}

.inactive{background-color: #ffb3b3 !important;}

.breadcrumb-container .back {
float: right;
padding-right: 64px;
padding-top: 38px;
font-size: 20px;
}
.breadcrumb-container .back a {
  position: relative;
  z-index: 2;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  text-shadow: 0 0 30px rgba(0,0,0,0.1), 0 0 30px rgba(0,0,0,0.1), 0 0 11px rgba(0,0,0,0.1);
}


.test-card ul li .img-sec{ margin-top: 10px; width: 13%; float: left;}
.test-card ul li .img-sec img{width: 30px; height: 30px; border-radius: 50%;}
.test-card ul li .test-title { width: 87%; float: right;}
.test-card .location-img{margin-left: 10px; width:16px;}
.cart-box-body-left ul li img{width: 16px;}

.badge {
    display: inline-block;
        border-radius: 54px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
        padding: 3px 7px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    padding: 
}

.badge-primary {
    color: #007bff !important;
    border: 1px solid #007bff;
    background: #e2effb;
}

.badge-success {
    
    background-color: #dff0d0 !important;
    border:1px solid #266900;
    color: #266900;
}

.badge-info {
    color: #004a70 !important;
    background-color: #e1f1f6;
    border:1px solid #004a70;
}

.badge-warning {
    color: #333 !important;
    background-color: #feecb5;
    border:1px solid #c2ad00;
}

.badge-danger {
    /* border:1px solid #dc3545; 
    background: #f5dbd9;
    color:#dc3545 !important; */
    background-color: #dff0d0 !important;
    border:1px solid #266900;
    color: #266900;
}

.list-search {text-align: center;}
.list-search input[type=text] {
  width: 60%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 40px;
  font-size: 15px;
  background-color: white;
  background-image: url('../images/searchicon.png');
  background-position: 10px 10px; 
  background-repeat: no-repeat;
  padding: 10px 20px 10px 40px;
}

.list-search input::-webkit-input-placeholder { /* Edge */
  color: #b7b5b5;
}
.list-search input:-ms-input-placeholder { /* Internet Explorer */
  color: #b7b5b5;
}
.list-search input::placeholder {
  color: #b7b5b5;
}

.wrapper header .my-acc-drop li a.btn2.dark { color: #fff;}
.wrapper header .my-acc-drop li a.btn2.dark:hover {color: #000;}