/* You can add global styles to this file, and also import other style files */
@import url("../src/assets/css/icons.css");
@import url("../src/assets/css/nav.css");
@import url("../src/assets/css/icheck-bootstrap.min.css");
@import url("../src/assets/css/custom.css");
@import url("../src/assets/css/responsive.css");
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

app-root,app-front-layout{display: block !important;}

.label{font-size: 13px !important; font-weight: 500 !important;}


table.dataTable thead th, table.dataTable thead td{ border-bottom:1px solid rgba(0,0,0,0.1) !important;}
table.dataTable.no-footer{border-bottom:1px solid rgba(0,0,0,0.3) !important;}

.dataTables_wrapper .dataTables_filter input{border:1px solid rgba(0,0,0,0.1) !important; height:30px !important; padding-left:10px !important;}
.dataTables_length select{border:1px solid rgba(0,0,0,0.1) !important; height:30px !important; padding-left:10px !important; width:100px !important;}
.dataTables_length label{font-weight:500 !important;}
.dataTables_filter label{font-weight:500 !important;}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover{ background:#3d4fb3 !important;
	color:#fff !important;}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover{ color:#fff !important;}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover{ background:#3d4fb3 !important;
	color:#fff !important;}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper

table.dataTable thead th, table.dataTable thead td{ padding-left:8px !important;}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active{color:#000;}
